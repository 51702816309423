var buttons = document.querySelectorAll('.button--queanclean');
console.log(buttons);
buttons.forEach(button => {
  button.addEventListener('click', (event) => {
    event.target.innerText = 'Redirecting...';
    var productId = event.target.getAttribute('data-product-id');
    document.querySelector('.redirect').classList.toggle('redirect--show');
    
    window.setTimeout(() => redirectToQueanClean(productId), 3000);
  })
});

function redirectToQueanClean(id) {
  window.location = "https://queanclean.co.uk/basket/?add-to-cart="+id;
}
